<template>
  <div>
    <vx-card actionable class="cardx" title="Attendence Edit">
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            style="width: 300px; z-index: 1000"
            placeholder="City"
            :options="cities"
            v-model="selected_city"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <datepicker
            :disabled-dates="disabledates"
            placeholder="Select Date"
            v-model="selected_date"
          ></datepicker>
        </vs-col>
      </vs-row>
      <vs-table id="tableattendance" class="gap" :data="spocs">
        <template slot="thead">
          <vs-th>Spoc</vs-th>
          <vs-th>Attendance</vs-th>
          <vs-th>Hours</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="tr.spoc_id" v-for="tr in data">
            <vs-td>{{ tr.spoc_name }}</vs-td>
            <vs-td>
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="2"
                >
                  <label>
                    <input
                      @change="vSelectUpdate(tr)"
                      type="radio"
                      :id="'att-present-' + tr.spoc_id"
                      :name="'att-' + tr.spoc_id"
                      value="present"
                      v-model="tr.type"
                    />Present
                  </label>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="2"
                >
                  <label>
                    <input
                      @change="vSelectUpdate(tr)"
                      type="radio"
                      :id="'att-leave-' + tr.spoc_id"
                      :name="'att-' + tr.spoc_id"
                      value="leave"
                      v-model="tr.type"
                    />Leave
                  </label>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="2"
                >
                  <label>
                    <input
                      @change="vSelectUpdate(tr)"
                      type="radio"
                      :id="'att-holiday-' + tr.spoc_id"
                      :name="'att-' + tr.spoc_id"
                      value="national_holiday"
                      v-model="tr.type"
                    />Holiday
                  </label>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="2"
                >
                  <label>
                    <input
                      @change="vSelectUpdate(tr)"
                      type="radio"
                      :id="'att-weekly-' + tr.spoc_id"
                      :name="'att-' + tr.spoc_id"
                      value="weekly_off"
                      v-model="tr.type"
                    />Weekly Off
                  </label>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="2"
                >
                  <label>
                    <input
                      @change="vSelectUpdate(tr)"
                      type="radio"
                      :id="'att-venue -' + tr.spoc_id"
                      :name="'att-' + tr.spoc_id"
                      value="venue"
                      v-model="tr.type"
                    />Venue
                  </label>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="2"
                >
                  <label>
                    <input
                      @change="vSelectUpdate(tr)"
                      type="radio"
                      :id="'att-training-' + tr.spoc_id"
                      :name="'att-' + tr.spoc_id"
                      value="training"
                      v-model="tr.type"
                    />Training
                  </label>
                  <!-- <vs-radio
                  color="dark"
                  @change="vSelectUpdate(tr)"
                  :id="'att-training-' + tr.id"
                  :vs-name="'att-' + tr.id"
                  value="training"
                  :checked="tr.type === 'training'"
                  >Traning</vs-radio>-->
                </vs-col>
              </vs-row>

              <!-- <v-select
              placeholder="Attendance"
              :options="attendanceOptions"
              style="width: 300px"
              v-model="tr.type"
              @input="vSelectUpdate(tr)"
              ></v-select>-->
            </vs-td>
            <vs-td>
              <vue-timepicker
                style="z-index: 1000"
                v-if="tr.type === 'training'"
                format="HH:mm"
                v-model="tr.minutes"
              ></vue-timepicker>
              <vs-button
                v-if="tr.type === 'training'"
                style="margin-left: 2%"
                size="small"
                @click="handleClose(tr)"
                >ok</vs-button
              >
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
export default {
  data() {
    return {
      disabledates: {
        from: new Date(),
        to: new Date(2019, 6, 1) // This feature was started on 5th Aug 2019
      },
      cities: [],
      spocs: [],
      attendanceOptions: [
        "present",
        "leave",
        "national_holiday",
        "weekly_off",
        "venue",
        "training"
      ],
      selected_city: "",
      selected_date: new Date()
    };
  },
  components: {
    "v-select": vSelect,
    Datepicker,
    VueTimepicker
  },
  computed: {},
  mounted() {
    // document.getElementById(
    //   "tableattendance"
    // ).children[1].children[0].style.height = "500px";
    this.getSpoc();
  },
  watch: {
    selected_city: function() {
      this.getAttendance();
    },
    selected_date: function() {
      this.getAttendance();
    }
  },
  methods: {
    handleClose(tr) {
      if (tr.minutes.HH === "" && tr.minutes.mm === "") {
        this.$vs.notify({
          title: "Select a time",
          text: "Select a time to continue",
          color: "warning"
        });
      } else {
        this.editattendance(tr);
      }
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(async response => {
          var unique = response.data.spocs
            .map(name => {
              return {
                count: 1,
                name: name.city
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.cities.push(key);
            }
          }
          this.cities = this.sortArrayAlphabetically(this.cities);
          this.selected_city = this.cities[0];
          this.getAttendance();
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getAttendance() {
      this.spocs = [];
      let url = `${
        constants.SERVER_API
      }getAttendance?selected_date=${this.datatoTimestamp(
        this.selected_date
      )}&city=${this.selected_city}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          response.data.attendance.forEach(spoc => {
            spoc.showInput = false;
            spoc.sync = false;
            if (spoc.minutes !== 0) {
              let timestring = this.getTimeFromMins(spoc.minutes);
              spoc.minutes = {
                HH: timestring.split(":")[0],
                mm: timestring.split(":")[1]
              };
            } else {
              spoc.minutes = {
                HH: "",
                mm: ""
              };
            }
          });
          this.spocs = response.data.attendance;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    editattendance(tr) {
      let minutes = 0;
      if (tr.minutes.HH !== "" && tr.minutes.mm !== "") {
        minutes = parseInt(tr.minutes.HH) * 60 + parseInt(tr.minutes.mm);
      }
      if (tr.type !== "training") {
        minutes = 0;
      }
      let url = `${constants.SERVER_API}editAttendance`;
      let obj = {
        attendance_id: tr.id,
        type: tr.type,
        minutes: minutes
      };
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          if (response.data.status === "error") {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              color: "danger",
              fixed: true,
              click: () => {}
            });
          } else {
            this.$vs.notify({
              title: "Updated",
              text: "Successfully updated",
              color: "success"
            });
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getTimeFromMins(mins) {
      if (mins >= 24 * 60 || mins < 0) {
        throw new RangeError(
          "Valid input should be greater than or equal to 0 and less than 1440."
        );
      }
      var h = (mins / 60) | 0,
        m = mins % 60 | 0;
      return moment
        .utc()
        .hours(h)
        .minutes(m)
        .format("hh:mm");
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    vSelectUpdate(value) {
      // console.log(value);
      if (value.type === `training`) {
        value.showInput = true;
        value.minutes = {
          HH: "",
          mm: ""
        };
      } else {
        value.showInput = false;
        this.editattendance(value);
      }
    }
  }
};
</script>

<style>
.gap {
  margin-top: 3%;
}
.vdp-datepicker {
  z-index: 1000;
}
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
</style>
